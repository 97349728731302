//base url

export const LOGIN_PAGE = 'https://dev-home.phillipsoutsourcing.net/login';
export const MAIN_MENU = 'https://dev-home.phillipsoutsourcing.net/home';
export const API_BASE_URL = 'https://dev-api.phillipsoutsourcing.net';

export const DOCUMENT_BASE_URL = 'https://s3.us-east-1.amazonaws.com/dev-api.phillipsoutsourcing.net';


//statistics
export const PAYROLL_DASHBOARD_STATISTICS = '/esm-dashboard-statistics';
export const ESM_DASHBOARD_STATISTICS = '/esm-dashboard-statistics';


//banks
export const BANKS = '/banks';
export const RESOLVE_BANK_ACCOUNT = '/api/resolve';
export const PFAS = '/pfa-names';


//general urls
export const COUNTRIES_URL = '/countries';

//users
export const USERS_URL = '/users';
export const GET_USER_AUTHORIZATION = '/get-user-authorization';
export const START_ONBOARDING = '/start-onboarding';

//company
export const EMPLOYEE_COMPANY_URL = '/get-employee-company';
export const COMPANY_FILE_URL = '/company-files';
export const CONFIRM_DOCUMENT_RECEIPT = '/confirm-document-receipt';

//employees
export const SEARCH_CITY = '/search-cities';
export const APPRAISAL_DOCUMENTS = '/appraisal-documents';
export const EMPLOYEE_VOTES = '/employee-votes';
export const SEARCH_LOCAL_GOVT = '/search-local-governments';
export const EMPLOYEE_EXITS = '/employee-exits';
export const EXIT_REQUEST = '/initiate-exit-requests';
export const UPDATE_EXIT_STAGE = '/update-exit-stage';
export const CONTINUE_EXIT_REQUEST = '/continue-exit-requests';
export const EMPLOYEE_QUERIES = '/employee-queries';
export const GET_EXIT_DOWNLOADABLES = '/get-exit-downloadables';
export const SAVE_QUERY_RESPONSE = '/save-query-response';
export const TRAINING_SCHEDULES = '/training-schedules';
export const GET_CANDIDATE_TASKS = '/get-candidate-tasks';
export const ACTIVATE_NEXT_TASK = '/activate-next-task';
export const SAVE_PAYSTUB_REQUESTS = '/request-paystubs';
export const SUBMIT_ONBOARDING_RECORD = '/submit-onboarding-record';
export const MARK_TASK_COMPLETED = '/mark-task-completed';
export const SAVE_EMPLOYEE_PROFILE = '/save-employee-profile';
export const SAVE_COVID_INFORMATION = '/save-covid-information';
export const GET_EMPLOYEE_PROFILE = '/get-employee-profile';
export const EMPLOYEE_PROFILE = '/employees';
export const WORK_EXPERIENCES = '/work-experiences';
export const PROF_QUALIFICATION = '/qualifications';
export const NEXT_KINS = '/next-kins';
export const GUARANTORS = '/guarantors';
export const BENEFICIARIES = '/beneficiaries';
export const DEPENDENTS = '/dependents';
export const EMPLOYEE_FILES = '/employee-files';
export const REMOVE_MY_DOCUMENT = '/remove-my-document';
export const OPTIONAL_FILES = '/optional-files';
export const EDU_RECORDS = '/educational-records';
export const SEARCH_UNIVERSITY = '/search-universities';
export const SEARCH_COURSES = '/search-courses';
export const SEARCH_DEGREES = '/search-degrees';
export const DEGREE_CLASSES = '/degree-classes';
export const RELATIONSHIPS = '/relationships';
export const ONBOARDING_DOCUMENTS = '/onboarding-documents';
export const GET_TASKS_DOCUMENTS = '/get-tasks-documents';
export const GET_DOWNLOADABLE_DOCUMENTS = '/get-downloadable-documents';
export const SEARCH_PERSONAL_TITLES = '/search-personal-titles';
export const GENOTYPES = '/genotypes';
export const BLOOD_GROUPS = '/blood-groups';
export const VACCINATION_FILES = '/vaccination-files';
export const EVENT_URL = '/events';
export const EVENT_FEEDBACK_URL = '/event-feedback';
export const USER_EVENT_URL = '/get-user-events';

export const TOGGLE_CHECK_IN = '/toggle-check-in';
export const GET_EMPLOYEE_TIME = '/get-employee-time';
export const SEARCH_COMPANY_EMPLOYEE = '/search-company-employee';
export const SEARCH_EMPLOYEE_LIST = '/search-employee-list';
export const SEARCH_EMPLOYEE_LIST_ALL = '/search-employee-list-all'



export const GET_EMPLOYEE_ATTENDANCE = '/get-employee-attendance';
export const SEARCH_SKILLS = '/search-skills';


export const GET_TODAY_CELEBRANTS = '/get-today-celebrants';
export const GET_HOLIDAYS = '/get-holidays';
export const GET_CELEBRANTS = '/coming-celebrants';
export const GET_NEW_HIRES = '/get-new-hires';
export const GET_DEPARTMENT_MEMBERS = '/get-department-members';
export const GET_REPORTING_MEMBERS = '/get-reporting-members';
export const ANNOUNCEMENTS_URL = '/announcements';
export const ANNOUNCEMENT_COMMENTS_URL = '/announcement-comments';
export const COMPANY_ANNOUNCEMENTS_URL = '/get-company-announcements';
export const SEARCH_ANNOUNCEMENTS = '/search-announcements';
export const SEARCH_QUERIES = '/search-queries';
export const ADD_ANNOUNCEMENT_REACTION = '/add-reaction';


//leave
export const LEAVE_REQUEST = '/request-new-leave';
export const LEAVE_RESUMPTION_REQUEST = '/leave-resumption-request';
export const CANCEL_LEAVE_REQUESTS = '/cancel-leave-requests';
export const GET_APPLICABLE_LEAVES = '/get-applicable-leaves';
export const LEAVE_APPLICATIONS = '/leave-applications';

export const REGULARIZE_ATTENDANCE = '/regularize-attendance';
export const REQUEST_REGULARIZATION = '/request-attendance-regularization';

//polls
export const GET_ACTIVE_POLL = '/get-active-poll';
export const GET_VOTES = '/get-votes';
export const SAVE_VOTES = '/save-votes';

//retreat

export const GET_ELIGIBLE_RETREAT_PARTNER = '/get-eligible-retreat-partners'
export const GET_ELIGIBLE_RETREAT_PARTNER_STATUS = '/get-retreat-partner-status'

export const CREATE_RETREAT_PARTNER = '/create-retreat-partner'

//Company Verification

export const GET_ACTIVE_PROFILE_VERIFICATION = '/get-active-profile-verification'
export const COMPLETE_VERIFICATION_URL = '/complete-profile-verification'

//Help Desk

export const SUPPORT_METRICS = '/get-support-metrics';
export const SUPPORT_TICKETS = '/supports';
export const SUPPORT_CATEGORIES = '/get-support-categories';